<template>
    <div>
        <div class="row">
            <b-button variant="outline-secondary secondary-border-light"
                      class="mr-2" @click="$bvModal.show('form');">
					        <span class="d-flex">
						        <i class="ri-add-line mr-1"></i>
					            {{ $t('new').toUpper() }}
					        </span>
            </b-button>
        </div>
        <div class="row" v-if="data">
            <b-table
                v-if="data.length>0"
                bordered
                striped
                responsive
                :items="data"
                :fields="fields"
                show-empty
                class="mb-5 table-dropdown no-scrollbar border rounded">
            </b-table>
        </div>
        <CommonModal id="form" size="lg">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('new_vinov_instruction').toUpper() }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <ValidationObserver ref="form" v-if="form.amount">
                    <div class="row">
                        <div class="col-12">
                            <ValidationProvider name="period_id" rules="required"
                                                v-slot="{valid, errors}">
                                <b-form-group>
                                    <label class="col-form-label pt-0">{{ $t('period') }}</label>
                                    <multi-selectbox :options="periods" ref="period_id"
                                        :validate-error="errors[0]"
                                        v-model="form.period_id"></multi-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12">
                            <div class="label-as-input-div">
                                <label>{{ $t('amount') }}</label>
                                <div>{{ form.amount | formatToCurrency(form.currency) }}</div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="label-as-input-div">
                                <label>{{ $t('number_of_installments') }}</label>
                                <div>{{ form.number_of_installments }}</div>
                            </div>
                        </div>

                        <div class="col-12">
                            <ValidationProvider name="guardian_national_id" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('guardian_national_id')">
                                    <b-form-input
                                        v-model="form.guardian_national_id"
                                        v-mask="'###########'"
                                    ></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-md-4 col-sm-6">
                            <ValidationProvider name="guardian_mobile_tel" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('guardian_mobile_tel')">
                                    <b-form-input
                                        v-model="form.guardian_mobile_tel"
                                        v-mask="'(5##) ### ## ##'"
                                    ></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]"
                                                             v-html="errors[0]"></b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </div>

                        <div class="col-12">
                            <b-button type="submit" @click="save" variant="primary">{{
                                    $t('save')
                                }}
                            </b-button>
                        </div>

                    </div>
                </ValidationObserver>
            </template>
        </CommonModal>
    </div>
</template>

<script>
    //Service
    import VinovInstructionService from "@/services/VinovInstructionService";
    //Component
    import qs from "qs";
    import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox.vue";
    import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox.vue";
    import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox.vue";

    export default {
        components: {
            CurrencySelectbox, MultiSelectbox, PaymentPeriodsSelectbox
        },
        props:{
            customer_id:{
                default: null
            },
            student_program_id:{
                default: null
            },
            registration_id:{
                default: null
            },
            paymentPlans:null
        },
        data() {
            return {
                periods:[],
                fields: [
                    {
                        label: this.toUpperCase('id'),
                        field: 'id',
                    },
                    {
                        label: this.toUpperCase('student_number'),
                        key: 'student_number',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('name'),
                        key: 'name',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('surname'),
                        key: 'surname',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('period'),
                        key: 'period_code',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('amount'),
                        key: 'amount',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('number_of_installments'),
                        key: 'number_of_installments',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('guardian_national_id'),
                        key: 'guardian_national_id',
                        sortable: false
                    },
                    {
                        label: this.toUpperCase('status'),
                        key: 'status',
                        sortable: false
                    },
                    {
                        key: 'created_at',
                        label: this.toUpperCase('created_at')
                    },
                    {key: 'dropdownTable', label: '#'}
                ],
                data:null,
                form:{
                    period_id:null,
                    amount:null
                }
            }
        },
        methods: {
            loadData(){
                const config = {
                    params: {
                        filter: {customer_id: this.customer_id},
                        limit: -1,
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false}),
                };
                VinovInstructionService.getAll(config).then(response=>{
                    this.data = response.data.data;
                }).catch(e=>{
                    this.showErrors(e);
                });
            },
            async save(){
                const isValid = await this.$refs.form.validate();
                if(isValid){
                    let formData = {...this.form};
                    VinovInstructionService.storeStudent(formData)
                    .then(response=>{
                        this.$bvModal.hide('form');
                        this.loadData();
                    }).catch(e=>{
                        this.showErrors(e, this.$refs.form);
                    });
                }
            }
        },
        mounted() {
            this.loadData();
            VinovInstructionService.student().then(response=>{
               if(response.data.data.period){
                   this.periods.push({value: response.data.data.period.id, text: response.data.data.period.code});
                   this.form.period_id=response.data.data.period.id;
               }
               this.form.payment_plan_id = response.data.data.payment_plan.id;
               this.form.amount = response.data.data.payment_plan.price;
               this.form.currency = response.data.data.payment_plan.currency;
               this.form.number_of_installments = response.data.data.payment_plan.number_of_installments;
            });
        }
    }
</script>
